(function (_) {
    "use strict";

    //Mostly pinched from MarionetteJS and BackboneJS

    window.scv = window.scv || {};

    window.scv.Object = function(options) {
        this.options = _.extend({}, _.result(this, "options"), options);

        this.initialize.apply(this, arguments);
    };

    window.scv.Object.extend = function (protoProps, staticProps) {
        var parent = this;
        var child;

        // The constructor function for the new subclass is either defined by you
        // (the "constructor" property in your `extend` definition), or defaulted
        // by us to simply call the parent constructor.
        if (protoProps && _.has(protoProps, 'constructor')) {
            child = protoProps.constructor;
        } else {
            child = function () { return parent.apply(this, arguments); };
        }

        // Add static properties to the constructor function, if supplied.
        _.extend(child, parent, staticProps);

        // Set the prototype chain to inherit from `parent`, without calling
        // `parent`'s constructor function and add the prototype properties.
        child.prototype = _.create(parent.prototype, protoProps);
        child.prototype.constructor = child;

        // Set a convenience property in case the parent's prototype is needed
        // later.
        child.__super__ = parent.prototype;

        return child;
    };

    _.extend(window.scv.Object.prototype, window.scv.Events, {
        //this is a noop method intended to be overridden by classes that extend from this base
        initialize: function () {}
    });

})(window._);