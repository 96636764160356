"use strict";

(function ($) {
    "use strict";

    window.scv = window.scv || {};

    window.scv.forgottenPassword = function (siteKey) {
        window.recaptchaOnload = function () {
            var captchaWidget = window.grecaptcha.render(
                "recaptchaWidget",
                { "sitekey": siteKey, "theme": "light" }
            );

            $("form[method=post]").not(".no-ajax").on("submit",
                    function () {
                        $("#emailerrormessage,#captchaerrormessage").addClass("hide");
                    })
                .on("validationerror",
                    function (e, data) {
                        if (data.CaptchaResponse && data.CaptchaResponse.Errors.length) {
                            $("#captchaerrormessage").removeClass("hide");
                        }
                        if (data.Email &&
                            data.Email.Errors.length &&
                            $.trim(data.Email.Value.AttemptedValue).length > 0) {
                            $("#emailerrormessage").removeClass("hide");
                        }
                        window.grecaptcha.reset(captchaWidget);
                    });

            $("#fpSubmit").removeAttr("disabled");
        };
    };
})(jQuery);