"use strict";

(function ($) {
    "use strict";

    window.scv = window.scv || {};

    window.scv.personalDetails = function (companyId) {
        var $countrySelect = $("#q6");
        
        if(!$countrySelect) return;
        
        $countrySelect.on("change", function() {
            $.ajax({
                url: "/PersonalDetails/GetCountryDialingCode?countryCode=" + this.value + "&CoId=" + companyId,
                type: "GET",
                cache: true,
            }).done(function (data) {
                $('.telephone-prefix').each(function() {
                    $(this).text(data.dialingCode);
                });
            });
        });
    };
})(jQuery);