/*
simple and easily extendable function for client side validation
add validation attributes to the elements that need validation e.g.   data-validation="required,minlength=6"
to extend just add other validation functions in the section below
run validation with: var isValid = $.validation.run() pass in a scope if you want e.g. var isValid = $.validation.run($('form:first'));
*/
(function (factory) {
    if (typeof define === "function" && define.amd) {
        define(["jquery"], factory);
    } else if (typeof exports === "object") {
        module.exports = factory(require("jquery"));
    } else {
        window.jQuery.validation = factory(window.jQuery);
    }
}(function ($) {
    return {
        run: function ($scope) {
            var formValid = true,
                me = this,
                //you can pass the elements or a surrounding element
                $checkEls = $scope.is("[data-validation]") ? $scope : $("[data-validation]", $scope);

            $checkEls.each(function () {
                if (!me._validField(this)) {
                    if (formValid) {
                        formValid = false;
                    }
                }
            });

            return formValid;
        },

        _validField: function (el) {
            var me = this,
                fieldValid = true,
                $el = $(el),
                ruleStr = $el.data("validation");

            $.each(ruleStr.split(","),
                function (ix, valRule) {
                    var rule = valRule.split("=");
                    if (rule.length === 1) {
                        rule.push("");
                    }
                    if (typeof (me[rule[0]]) === "function") {
                        fieldValid = me[rule[0]]($el, rule[1]);
                        if (!fieldValid) {
                            $el.closest(".form-group").addClass("has-error");
                            return fieldValid;
                        }
                    }
                });

            if (fieldValid) {
                $el.closest(".form-group").removeClass("has-error");
            }

            return fieldValid;
        },

        //VALIDATION FUNCTIONS

        required: function ($el) {
            return ($.trim($el.val()) !== "");
        },
        minlength: function ($el, parameter) {
            return $el.val().length >= parameter;
        },
        maxlength: function ($el, parameter) {
            return $el.val().length <= parameter;
        },
        maxval: function ($el, parameter) {
            var val = $el.val();
            return val.length === 0 || parseInt(val, 10) <= parameter;
        },
        minval: function ($el, parameter) {
            var val = $el.val();
            return val.length === 0 || parseInt(val, 10) >= parameter;
        },
        regex: function ($el, parameter) {
            var regExp = new RegExp(parameter),
                value = $el.val(),
                matches = regExp.exec(value);
            //no value
            if (!value || !value.length) {
                return true;
            }
            return (!(!matches || !matches.length) && matches[0].length === value.length);
        },
        email: function ($el) {
            var regularExpression =
                /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
            return this.regex($el, regularExpression);
        },
        compareto: function ($el, otherElId) {
            var val = $el.val(),
                $otherEl = $("#" + otherElId);
            if ($otherEl.length === 1) {
                return val === $otherEl.val();
            }
            return true;
        },
        numeric: function ($el) {
            return this.regex($el, "^\\d*$");
        },
        range: function ($el, range) {
            var ranges = range.split(":"),
                low = parseInt(ranges[0]),
                high = parseInt(ranges[1]),
                value = parseInt($el.val());
            return (!(isNaN(low) || isNaN(high) || isNaN(value)) && value >= low && value <= high);
        }
    };
}));