(function ($, _) {
    "use strict";

    window.scv = window.scv || {};

    var DropDown = window.scv.Object.extend({
        initialize: function (options) {
            var me = this;

            this.$el = $(options.selector)
                .on("change",
                    function () {
                        me.trigger("change", me);
                    });

            if (options.parent) {
                options.parent.on("change",
                    function () {
                        me.populate(options.parent.$el.val());
                    });
            }
        },

        populate: function (parentValue) {
            var me = this;
            this.$el.find("option").remove();

            if (!parentValue) {
                me.trigger("change", me);
            } else {
                $.getJSON(me.options.url(parentValue, me),
                    function (results) {
                        _.each(results,
                            function (result) {
                                var title = me.options.titleName == null ? result : result[me.options.titleName],
                                    id = me.options.idName == null ? result : result[me.options.idName];

                                me.$el.append('<option value="' + id + '">' + title + '</option>');
                            });
                    }).then(function () {
                    me.trigger("change", me);
                });
            }
        }
    });

    window.scv.cascadingDropdown = window.scv.Object.extend({
        initialize: function (options) {
            var parent = null,
                me = this;
            this.dropdowns = [];

            _.each(options.dropdowns,
                function (selector) {
                    var ddown = new DropDown({
                        selector: selector,
                        parent: parent,
                        url: options.url,
                        idName: options.idName,
                        titleName: options.titleName
                    });
                    me.dropdowns.push(ddown);
                    parent = ddown;
                });
        }
    });
})(jQuery, window._);