(function ($) {
    "use strict";

    // Creating modal dialog's DOM
    var $dialog = $(
		'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
		'<div class="modal-dialog modal-m">' +
		'<div class="modal-content">' +
			'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
			'<div class="modal-body">' +
				'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
			"</div>" +
		"</div></div></div>");

    $.loadingModal = {
        show: function (message, options) {
            if (typeof options === "undefined") {
                options = {};
            }
            if (typeof message === "undefined") {
                message = "Please wait...";
            }

            var settings = $.extend({
                    dialogSize: "sm",
                    progressType: "",
                    onHide: null
                },
                options);

            $dialog.find(".modal-dialog").attr("class", "modal-dialog").addClass("modal-" + settings.dialogSize)
                .end().find(".progress-bar").attr("class", "progress-bar");

            if (settings.progressType) {
                $dialog.find(".progress-bar").addClass("progress-bar-" + settings.progressType);
            }

            $dialog.find("h3").text(message);

            $dialog.off("hidden.bs.modal");

            if (typeof settings.onHide === "function") {
                $dialog.one("hidden.bs.modal",
                    function () {
                        settings.onHide.call($dialog);
                    });
            }

            $dialog.modal();
        },
        /**
		 * Closes dialog
		 */
        hide: function () {
            $dialog.modal("hide");
        }
    };

})(window.jQuery);