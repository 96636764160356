(function ($, _, rg4js) {
    "use strict";

    function highlightFields(response, $context) {
        $.each(response,
            function (propName, val) {
                if (val.Errors.length > 0) {
                    var escapedName = propName.replace(/(:|\.|\[|\])/g, "\\$1"),
                        $el = $("[name=\"" + escapedName + "\"]", $context);

                    if (!$el.length) {
                        $el = $("#" + escapedName, $context);
                    }

                    $el.closest(".form-group").addClass("has-error");
                }
            });
    }

    function redirect(data) {
        if (data.redirect) {
            window.location = data.redirect;
        } else {
            window.scrollTo(0, 0);
            $("body").append("<div class=\"modal-backdrop in\"></div>");
            window.location.reload();
        }
    }

    $("form[method=post]")
        .not(".no-ajax")
        .on("submit",
            function () {
                var $submitBtn = $(this).find("[type=\"submit\"]");

                $submitBtn.button("loading");
                $(window).unbind();

                var $this = $(this),
                    formData = $this.serialize();

                $this.find(".has-error").removeClass("has-error");

                $.ajax({
                        url: $this.attr("action"),
                        type: "post",
                        data: formData,
                        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
                        dataType: "json"
                    })
                    .done(function (data, textStatus, jqXHR) {
                        if (jqXHR.status === 200) {
                            redirect(data);
                        } else {
                            $submitBtn.button("reset");
                        }
                    })
                    .fail(function (xhr, textStatus) {
                        try {
                            var data = JSON.parse(xhr.responseText);
                            highlightFields(data, $this);
                            $("html,body").animate({ scrollTop: $this.offset().top }, "slow");
                            $this.trigger("validationerror", data);
                            $submitBtn.button("reset");
                        } catch (e) {
                            if (rg4js) {
                                try {
                                    throw new Error("Form Submit Error: " + textStatus);
                                } catch (err) {
                                    rg4js("send",
                                        {
                                            error: err,
                                            customData: [
                                                {
                                                    status: xhr.status,
                                                    statusText: textStatus,
                                                    type: "post",
                                                    url: $this.attr("action"),
                                                    ajaxErrorMessage: "AJAX Error: " + textStatus,
                                                    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
                                                    requestData: formData && formData.slice
                                                        ? formData.slice(0, 10240)
                                                        : undefined,
                                                    responseData: xhr.responseText && xhr.responseText.slice
                                                        ? xhr.responseText.slice(0, 10240)
                                                        : undefined
                                                }
                                            ]
                                        });
                                }
                            } else {
                                throw e;
                            }
                        }
                    });

                return false;
            });

})(window.jQuery, window._, window.rg4js);
