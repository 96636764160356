(function ($) {
    "use strict";

    window.scv = window.scv || {};

    window.scv.task = function (hasForm) {

        if (window.Dropzone) {
            window.Dropzone.autoDiscover = false;
        }

        $("#completebutton")
            .click(function (e) {
                e.preventDefault();
                e.stopPropagation();

                if (hasForm) {
                    $("#formRequiredModal").modal("show");
                } else {
                    $("#completeConfirmModal").modal("show");
                }
            });

        $("#completeModalLink")
            .click(function (e) {
                e.preventDefault();
                var $btn = $(this);
                if (!$btn.hasClass("loading")) {
                    if (hasForm) {
                        $("#completeConfirmModal").modal("hide");
                        $("#frmMoreInfo").submit();
                    } else {
                        $btn.button("loading");
                        window.location.href = $btn.data("completeurl");
                    }
                }
            });

        $("#mifsubmit")
            .click(function (e) {
                e.preventDefault();
                var $this = $(this);

                if (!$this.hasClass("disabled")) {
                    $("input[name=submit]").val("true");
                    $("#completeConfirmModal").modal("show");
                }
            });

        $("#mifsave")
            .click(function () {
                var $this = $(this);
                if (!$this.hasClass("disabled")) {
                    $("input[name=submit]").val("false");
                }
            });
    };
})(jQuery);